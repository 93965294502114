import Swal from 'sweetalert2'
import AutoSaver from '~/scripts/lib/AutoSaver.js'
import Select2 from '~/scripts/lib/Select2.js'
import 'animate.css';
import {
  setupSelectFilters,
  queryParams as utilsQueryParams,
} from '~/scripts/lib/utils.js'

export default class CompanyAdmin {
  constructor() {
    this.addEvents();
  }

  addEvents() {
    this.autoSaver = new AutoSaver();
    this.setupDeptSelect();
    setupSelectFilters();

    $(document).bind("turbo:before-fetch-request", this.removeEvents.bind(this));

    if ($("#company-admin-content-wrapper.paid").length === 0 && $(".company-admin-wrapper.paid").length === 0) {
      this.lockUnpaidCompanyAdmin();
      return;
    }
  }

  setupDeptSelect() {
    new Select2(this.bindMenuDeptSelect);
  }

  bindMenuDeptSelect() {
    var $select = $('#dept-select').select2();
    $select.on('select2:select', function() {
      var $this = $(this),
          queryObj,
          url;

      queryObj = utilsQueryParams();
      queryObj[$this.prop('name')] = $this.val();
      var queryParams = $.param(queryObj);

      url = window.location.pathname + "?" + queryParams;

      if (window.location.hash !== "") {
        url += window.location.hash;
      }

      window.location = url;
    });
  }

  removeEvents() {
    $(window).unbind("ajaxify:success:comment_add", this.add);
  }

  lockUnpaidCompanyAdmin() {
    this.$companyWrapper = $(".company-admin-wrapper");

    $(".iOSCheckContainer").off('mousedown mousemove touchstart click');

    this.$companyWrapper.on('change mousedown touchstart click', '.iOSCheckContainer, input[type=checkbox], input[type=file]', function(e) {
      promptToPay(e);
      return false;
    });

    this.$companyWrapper.on('click', 'a:not(.unlocked):not(#upgrade-link),button,input[type=submit]:not(.unlocked)', function(e) {
      promptToPay(e);
      return false;
    });

    $("#company-admin-content-wrapper select").focus(function() {
      $(this).data('lastSelected', $(this).find('option:selected'));
    });

    var $selects = $('#company-admin-content-wrapper select');
    $selects.change(function(e) {
      $(this).data('lastSelected').attr('selected', true);
      promptToPay(e);
      return false;
    });
  }

  autosaveSetting(settingSelector, done, fail, always, abort) {
    this.autoSaver.autoSave(settingSelector, done, fail, always, abort);
  }
}

function promptToPay(e) {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();

  Swal.fire({
    imageUrl: "/assets/illustrations/trophy-small.jpg",
    title: "Engage Your Staff",
    text: 'Successful companies using Recognize customized their badges and more. Upgrade to start customizing.',
    showCancelButton: true,
    customClass: "upgrade-confirm-button",
    cancelButtonText: "I'll think about it",
    confirmButtonText: "Upgrade",
    showClass: { popup: 'animate__animated animate__fadeInDown animate__faster' },
    hideClass: { popup: 'animate__animated animate__fadeOutUp animate__faster' }
  }).then(function(result) {
    if (result.value) {
      window.location = '/welcome/?upgrade=true';
    }
  });
}
