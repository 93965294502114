export default class {
  constructor() {
    var integrations = new window.R.Integrations();

    if (!this.idp) {
      this.idp = new window.R.IdpRedirecter();
      this.idp.addEventsForAuthFields();
    }
  }
}
