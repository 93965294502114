export default class AutoSaver {
  autoSave(settingSelector, done, fail, always, abort) {
    $(document).on('change', settingSelector, function() {
      var $setting = $(this);
      var endpoint = $setting.data('endpoint') || $setting.parents('form').prop('action');

      var data = {};

      if ($setting.prop('type') === 'checkbox') {
        data[$setting.prop('name')] = $setting.prop('checked');
      } else {
        var val = $setting.val();
        // adjust empty arrays to have empty string in them
        // b/c for some reason jquery has a bug (or maybe just in this version)
        // that it will not send an empty array
        // Need this for multi-selects when they are cleared out (and perhaps other controls)
        // Ref1: https://bugs.jquery.com/ticket/10292
        // Ref2: https://stackoverflow.com/questions/6622224/jquery-removes-empty-arrays-when-sending
        if (Array.isArray(val) && val.length === 0) {
          val = null;
        }
        data[$setting.prop('name')] = val;
      }

      if (abort && abort($setting)) {
        return;
      }

      var promise = $.ajax({
        url: endpoint,
        type: 'PUT',
        data: data,
        dataType: 'json'
      });

      promise.done(function() {
        window.R.utils.renderFeedbackMessageAfterSave("Saved!", $setting, 3000, "success");
      }).fail(function(e) {
        let errorMessage = !e.responseJSON ? "Something went wrong. Please refresh and retry." : Object.values(e.responseJSON.errors).flat()[0]
        window.R.utils.renderFeedbackMessageAfterSave(errorMessage, $setting, 5000, "danger");
      }).done(done).fail(fail).always(always);
    });

    $(document).one("turbo:before-cache", function() {
      $(document).off('change', settingSelector);
    });
  }
}
